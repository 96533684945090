<template>
  <v-card class="" flat color="transperant">
    <v-card-text class="px-0 py-2 pb-4">
      <span class="text-h4 py-3 grey--text text--darken-2">{{
        props.name || ""
      }}</span>

      <v-spacer></v-spacer>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "pageHeader",
  components: {},
  methods: {},
  props: ["props"],
};
</script>
